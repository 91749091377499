.car-details-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.car-details-container.light {
  background-color: #f0f0f0;
  color: #333;
}

.car-details-container.dark {
  background-color: #1a202c;
  color: #f0f0f0;
}

.car-details {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark .car-details {
  background-color: #2d3748;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

.car-details-image {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.car-details-info {
  padding: 20px;
}

.car-details-info h1 {
  margin: 0 0 20px;
  font-size: 1.8em;
  color: #333;
}

.dark .car-details-info h1 {
  color: #f0f0f0;
}

.car-details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.car-details-grid p {
  margin: 5px 0;
  color: #666;
  font-size: 1em;
}

.dark .car-details-grid p {
  color: #a0aec0;
}

.reward-info-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dark .reward-info-box {
  background-color: #2d3748;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

.reward-info-box h3 {
  margin: 0 0 15px;
  font-size: 1.4em;
  color: #333;
}

.dark .reward-info-box h3 {
  color: #f0f0f0;
}

.reward-info-box p {
  margin: 10px 0;
  color: #666;
  font-size: 1em;
}

.dark .reward-info-box p {
  color: #a0aec0;
}

.loading, .error, .not-found {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

.dark .loading, .dark .error, .dark .not-found {
  color: #a0aec0;
}

.error {
  color: #d32f2f;
}

.dark .error {
  color: #f56565;
}

@media (max-width: 768px) {
  .car-details-image {
    height: 200px;
  }

  .car-details-info h1 {
    font-size: 1.5em;
  }

  .car-details-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .car-details-image {
    height: 150px;
  }

  .car-details-info h1 {
    font-size: 1.2em;
  }

  .car-details-grid p {
    font-size: 0.9em;
  }

  .loading, .error, .not-found {
    font-size: 1em;
  }
}

.footer {
  margin-top: 40px;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #eaeaea;
}

.dark .footer {
  border-top-color: #4a5568;
}

.footer a {
  margin: 0 10px;
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.dark .footer a {
  color: #a0aec0;
}

.footer a:hover {
  text-decoration: underline;
}

.car-details-container.owned {
  background-color: rgba(0, 128, 0, 0.1);
}

.car-details-info .toggle-ownership {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.car-details-info .toggle-ownership:hover {
  background-color: #2980b9;
}