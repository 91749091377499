.legal-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
}

.legal-page h1 {
    font-size: 2.5em;
    color: #2c3e50;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.legal-page h2 {
    font-size: 1.8em;
    color: #2980b9;
    margin-top: 30px;
    margin-bottom: 15px;
}

.legal-page p {
    margin-bottom: 15px;
}

.legal-page ul {
    margin-bottom: 15px;
    padding-left: 30px;
}

.legal-page li {
    margin-bottom: 5px;
}

.legal-page a {
    color: #3498db;
    text-decoration: none;
}

.legal-page a:hover {
    text-decoration: underline;
}

.legal-page .last-updated {
    font-style: italic;
    color: #7f8c8d;
    margin-bottom: 20px;
}

.legal-page .return-home {
    display: inline-block;
    margin-top: 30px;
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.legal-page .return-home:hover {
    background-color: #2980b9;
    text-decoration: none;
}