.car-list-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.car-list-container.light {
  background-color: #f0f0f0;
  color: #333;
}

.car-list-container.dark {
  background-color: #1a202c;
  color: #f0f0f0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.home-link {
  text-decoration: none;
  color: #3498db;
  font-weight: bold;
  font-size: 18px;
  transition: color 0.3s ease;
}

.home-link:hover {
  color: #2980b9;
}

.twitter-link {
  color: #1DA1F2;
  transition: color 0.3s ease;
}

.twitter-link:hover {
  color: #0c85d0;
}

h1 {
  margin: 0;
  color: #333;
  font-size: 24px;
}

.filters {
  margin-bottom: 20px;
}

.filters-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.filters-row > * {
  flex: 1;
  min-width: 150px;
}

.filters-row select,
.filters-row input,
.filters-row button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 16px;
  height: 40px;
  line-height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dark .filters-row select,
.dark .filters-row input,
.dark .filters-row button {
  background-color: #2d3748;
  border-color: #4a5568;
  color: #f0f0f0;
}

.filters-row select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="black" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 30px;
}

.dark .filters-row select {
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
}

.filters-row input[type="text"] {
  padding-left: 12px;
}

.clear-filters-btn {
  cursor: pointer;
  transition: background-color 0.3s;
  font-weight: normal;
}

.clear-filters-btn:hover {
  background-color: #c0392b;
  color: white;
}

.car-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 15px;
}

.car-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  text-decoration: none;
  color: inherit;
  position: relative;
}

.dark .car-card {
  background-color: #2d3748;
  box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1);
}

.car-card:hover {
  transform: translateY(-5px);
}

.car-image {
  height: 120px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.car-info {
  padding: 12px;
  position: relative;
}

.car-info h2 {
  margin: 0 0 8px;
  font-size: 1.1em;
  color: #333;
}

.dark .car-info h2 {
  color: #f0f0f0;
}

.car-info p {
  margin: 4px 0;
  color: #666;
  font-size: 0.85em;
}

.dark .car-info p {
  color: #a0aec0;
}

.car-specs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;
  margin-top: 8px;
  font-size: 0.75em;
  position: relative;
  z-index: 1;
}

.car-specs p {
  margin: 0;
}

.reward-indicator {
  position: absolute;
  bottom: 30px;
  right: 10px;
  font-size: 1.2em;
}

/* Ownership checkbox styles */
.ownership-checkbox {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 2;
}

.ownership-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 4px;
}

.dark .checkmark {
  border-color: #fff;
}

.ownership-checkbox:hover input ~ .checkmark {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark .ownership-checkbox:hover input ~ .checkmark {
  background-color: rgba(255, 255, 255, 0.1);
}

.ownership-checkbox input:checked ~ .checkmark {
  background-color: #000;
}

.dark .ownership-checkbox input:checked ~ .checkmark {
  background-color: #fff;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.ownership-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.ownership-checkbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.dark .ownership-checkbox .checkmark:after {
  border-color: #000;
}

/* Loading animation for ownership checkbox */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ownership-checkbox.loading .checkmark {
  border-color: transparent;
}

.ownership-checkbox.loading .checkmark:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #333;
  animation: spin 0.8s linear infinite;
  z-index: 3;
}

.dark .ownership-checkbox.loading .checkmark:before {
  border-color: #666;
  border-top-color: #fff;
}

@media (max-width: 768px) {
  .filters-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }

  .filters-row > * {
    min-width: 0;
  }

  .filters-row .search-input {
    grid-column: 1 / -1;
  }

  .filters-row .clear-filters-btn {
    font-size: 16px;
    padding: 8px 12px;
    height: 40px;
  }

  .car-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5em;
  }

  .car-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .car-info h2 {
    font-size: 1em;
  }

  .car-info p {
    font-size: 0.8em;
  }

  .car-specs {
    font-size: 0.7em;
  }
}

.footer {
  margin-top: 40px;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #eaeaea;
}

.footer a {
  margin: 0 10px;
  color: #666;
  text-decoration: none;
  font-size: 14px;
}

.dark .footer {
  border-top-color: #4a5568;
}

.dark .footer a {
  color: #a0aec0;
}

.footer a:hover {
  text-decoration: underline;
}